import React from 'react';

function PlayerCard( props ) {

    return (
        <div className='playercard'>
            <img 
                className='playercard__shot'
                src={require('../../images/card_shots.svg').default}
                alt='выстрел'
            ></img>
            <h2 className='playercard__title'><span>Карта</span> игрок<span>а</span></h2>
            <div className='playercard__card_container'>
                <div className='playercard__card_padding'>
                    <div className='playercard__card playercard__card_content'>
                        <div className='playercard__content-upper'>
                            <img
                                className='playercard__logo'
                                src={require('../../images/card/logo_card_front.png')}
                                alt='logo'
                            >
                            </img>
                            <div className='playercard__level_container'>
                                <h3 className='playercard__level'>23456</h3>
                                <img 
                                    className='playercard__bullet'
                                    src={require('../../images/card/levels.png')}  
                                    alt='bullets' 
                                >               
                                </img>
                            </div>
                        </div>
                        <div className='playercard__shots'>
                            <img
                                className='playercard__target-shot'
                                src={require('../../images/card/target.png')}
                                alt='terget'
                            ></img>
                            <img
                                className='playercard__target-shot'
                                src={require('../../images/card/target.png')}
                                alt='terget'
                            ></img>
                            <img
                                className='playercard__target-shot'
                                src={require('../../images/card/shot.png')}
                                alt='shot'
                            ></img>
                            <img
                                className='playercard__target-shot'
                                src={require('../../images/card/target.png')}
                                alt='terget'
                            ></img>
                            <img
                                className='playercard__target-shot'
                                src={require('../../images/card/target.png')}
                                alt='terget'
                            ></img>
                        </div>
                        <div className='playercard__content_lower'>
                            <p className='playercard__count-win'>Количество побед в серии: <span>2</span></p>
                            <h3 className='playercard__nikname'>@romashka</h3>
                        </div>
                    </div>
                </div>
                <div className='playercard__card_padding'>
                    <div className='playercard__card playercard__card_back'>
                        <div className='playercard__content-upper'>
                            <img
                                className='playercard__logo'
                                src={require('../../images/card/logo_card_back.png')}
                                alt='logo'
                            >
                            </img>
                            <h3 className='playercard__nikname'>@romashka</h3>
                        </div>
                        <div className='playercard__content_lower-back'>
                            <img 
                                className='playercard__qr'
                                src={require('../../images/card/qr.png')}
                                alt="qr"
                            ></img>
                            <p className='playercard__back_caption'>Для более быстрого поиска вашего аккаунта покажите qr-код работнику заведения</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayerCard;

