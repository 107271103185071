
const ratingList = [
    {
        "avatar" : "women",
        "nikname" : "@dusya150dragon300",
        "progress" :  "239104856",
        "level" : "4",
        "country" : "Россия",
        "city" : "Санкт-Петербург"
    },
    {
        "avatar" : "man",
        "nikname" : "@dusya150drerteagon300",
        "progress" :  "239104856",
        "level" : "3",
        "country" : "Россия",
        "city" : "Санкт-Петербург"
    },
    {
        "avatar" : "women",
        "nikname" : "@dusya150dragonrertrgrgegehtherertertert300",
        "progress" :  "239104856",
        "level" : "4",
        "country" : "Сербия",
        "city" : "Санкт-Петербург"
    },
    {
        "avatar" : "man",
        "nikname" : "@dusya150eedragon300",
        "progress" :  "239104856",
        "level" : "6",
        "country" : "Россия",
        "city" : "Санкт-Петербург"
    },
    {
        "avatar" : "man",
        "nikname" : "@dusyaon300",
        "progress" :  "239104856",
        "level" : "1",
        "country" : "Россия",
        "city" : "Санкт-Петербург"
    },
    {
        "avatar" : "man",
        "nikname" : "@dusya150dragon300",
        "progress" :  "239104856",
        "level" : "6",
        "country" : "Россия",
        "city" : "Санкт-Петербург"
    },
    {
        "avatar" : "man",
        "nikname" : "@dusya150dragon300",
        "progress" :  "239104856",
        "level" : "1",
        "country" : "Россия",
        "city" : "Санкт-Петербург"
    },
]

const barList = [
    {
        name: "Огонек",
        country: "Россия",
        city: "Санкт-Петребург",
        address: "ул. Лермонтова, д.12",
        drink: {
            item1: "Капучино Единорог на волне успеха",
            item2: "Коктейль Капибара",
            item3: "Коктейль Мохито",
        }
    },
    {
        name: "Бар 'Сияние'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Ломоносова, д.24",
        drink: {
            item1: "Буря в стакане",
            item2: "Белые ночи",
            item3: "Северное сияние",
        }
    },
    {
        name: "Бар 'Старый город'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "пр. Ветеранов, д.110",
        drink: {
            item1: "Петербургский ветер",
            item2: "Славянский шторм",
            item3: "Княжественный коктейль",
        }
    },
    {
        name: "Бар 'Магия фонтанов'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "наб. Фонтанки, д.58",
        drink: {
            item1: "Фонтанное волшебство",
            item2: "Ночной шторм",
            item3: "Сияние на воде",
        }
    },
    {
        name: "Бар 'Лунный свет'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Луначарского, д.8",
        drink: {
            item1: "Лунный свет",
            item2: "Ночные приключения",
            item3: "Лунный танец",
        }
    },
    {
        name: "Бар 'Арктические сияния'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "пр. Савушкина, д.105",
        drink: {
            item1: "Арктический бриз",
            item2: "Северное сияние",
            item3: "Полярная ночь",
        }
    },
    {
        name: "Бар 'Королевский приют'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Большая Московская, д.8",
        drink: {
            item1: "Королевский коктейль",
            item2: "Аристократический ужин",
            item3: "Роял Грей",
        }
    },
    {
        name: "Бар 'Золотой глобус'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Гривцова, д.14",
        drink: {
            item1: "Золотая луна",
            item2: "Глобальное вино",
            item3: "Золотой шторм",
        }
    },
    {
        name: "Бар 'Капитанская бочка'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "пр. Пискаревский, д.32",
        drink: {
            item1: "Королевское пламя",
            item2: "Морской бриз",
            item3: "Штормовой коктейль",
        }
    },
    {
        name: "Бар 'Звездное небо'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "пр. Невский, д.50",
        drink: {
            item1: "Звездопад",
            item2: "Космический коктейль",
            item3: "Галактическая чаша",
        }
    },
    {
        name: "Бар 'Мистический закат'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Большая Пушкарская, д.10",
        drink: {
            item1: "Волшебный закат",
            item2: "Магическая пена",
            item3: "Лунный коктейль",
        }
    },
    {
        name: "Бар 'Веселый робот'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Галерная, д.12",
        drink: {
            item1: "Роботизированное пиво",
            item2: "Электрический шторм",
            item3: "Механический мартини",
        }
    },
    {
        name: "Бар 'Феерический сад'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "пр. Марата, д.25",
        drink: {
            item1: "Феерический напиток",
            item2: "Эльфийский коктейль",
            item3: "Лесной зелье",
        }
    },
    {
        name: "Бар 'Легендарный пират'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Миллионная, д.10",
        drink: {
            item1: "Пиратское сокровище",
            item2: "Корабельный коктейль",
            item3: "Пиратский ром",
        }
    },
    {
        name: "Бар 'Лазурное побережье'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "наб. реки Фонтанки, д.15",
        drink: {
            item1: "Солнечный бриз",
            item2: "Морской коктейль",
            item3: "Лазурная волна",
        }
    },
    {
        name: "Бар 'Арктические сияния'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Марсово поле, д.5",
        drink: {
            item1: "Северное сияние",
            item2: "Полярная ночь",
            item3: "Арктический лед",
        }
    },
    {
        name: "Бар 'Фантастическая галактика'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "пл. Восстания, д.20",
        drink: {
            item1: "Звездное созвездие",
            item2: "Космическая эйфория",
            item3: "Галактическая мечта",
        }
    },
    {
        name: "Бар 'Подземный водопад'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Малая Садовая, д.8",
        drink: {
            item1: "Пещерная река",
            item2: "Подземный поток",
            item3: "Глубокая пещера",
        }
    },
    {
        name: "Бар 'Сказочная долина'",
        country: "Россия",
        city: "Санкт-Петербург",
        address: "ул. Лесная, д.3",
        drink: {
            item1: "Волшебная поляна",
            item2: "Лесная сказка",
            item3: "Волшебный ключ",
        }
    },
]

const historyList = [
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара",
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
    {
        date: "05.04.2024",
        place: "Аппетитный перерыв",
        address: "ул. Профессора Попова, д. 31",
        order: {
            order1: "Капучино Единорог на волне успеха",
            order2: "Коктейль Капибара"
        },
        coin: "356"
    },
    {
        date: "10.04.2024",
        place: "Вкусное удовольствие",
        address: "пр-т Мира, д. 15",
        order: {
            order1: "Эспрессо Вдохновение",
            order2: "Смузи Витаминный заряд"
        },
        coin: "420"
    },
    {
        date: "15.04.2024",
        place: "Гастрономическое приключение",
        address: "ул. Ломоносова, д. 8",
        order: {
            order1: "Латте Лесные ягоды",
            order2: "Чай Имбирный бодрость"
        },
        coin: "298"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "20.04.2024",
        place: "Кулинарный оазис",
        address: "ул. Садовая, д. 22",
        order: {
            order1: "Американо Утренняя свежесть",
            order2: "Фруктовый коктейль Энергия"
        },
        coin: "512"
    },
    {
        date: "25.04.2024",
        place: "Гастрономическое наслаждение",
        address: "пр-т Ленина, д. 45",
        order: {
            order1: "Мокко Лесные ягоды",
            order2: "Фреш Цитрусовый заряд",
        },
        coin: "389"
    },
];

const CountryList = [
    { value: 'RUS', label: 'Россия' },
    { value: 'BEL', label: 'Белорусь' },
    { value: 'KAZ', label: 'Казахстан' },
];

const CityList = [
    { value: 'moscow', label: 'Москва', country: 'RUS' },
    { value: 'spb', label: 'Санкт-Петербург', country: 'RUS' },
    { value: 'kazan', label: 'Казань', country: 'RUS' },
    { value: 'orenburg', label: 'Оренбург', country: 'RUS' },
    { value: 'minsk', label: 'Минск', country: 'BEL' },
    { value: 'gomel', label: 'Гомель', country: 'BEL' },
    { value: 'vitebsk', label: 'Витебск', country: 'BEL' },
    { value: 'astana', label: 'Астана', country: 'KAZ' },
    { value: 'almaty', label: 'Алматы', country: 'KAZ' },
    { value: 'karaganda', label: 'Караганда', country: 'KAZ' },
];

export {
    ratingList,
    barList,
    historyList,
    CountryList,
    CityList
}


