import React from 'react';

function Video() {
  return (
    <section className="video">
        <div className="video__container">
            <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/ZQ2nCGawrSY" 
                title="Rihanna - Russian Roulette" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
            >
            </iframe>
        </div>
    </section>
  );
}

export default Video;