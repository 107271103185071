import React, { useState, useEffect } from 'react';
import { historyList } from '../../constants/data';

function HistoryGames({ windowWidth }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const maxPagesToShow = windowWidth <= 860 ? 2 : 4;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = historyList.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(historyList.length / itemsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= 6) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`history__btn_pagin ${currentPage === i ? 'history__pagin_page-active' : ''}`}
                        onClick={() => handleClick(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            if (currentPage <= maxPagesToShow) {
                for (let i = 1; i <= maxPagesToShow; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            className={`history__btn_pagin ${currentPage === i ? 'history__pagin_page-active' : ''}`}
                            onClick={() => handleClick(i)}
                        >
                            {i}
                        </button>
                    );
                }
                pageNumbers.push(<button key="ellipsis1" className="history__pagin_ellipsis">...</button>);
                pageNumbers.push(
                    <button
                        key={totalPages}
                        className={`history__btn_pagin ${currentPage === totalPages ? 'history__pagin_page-active' : ''}`}
                        onClick={() => handleClick(totalPages)}
                    >
                        {totalPages}
                    </button>
                );
            } else if (currentPage > totalPages - maxPagesToShow) {
                pageNumbers.push(
                    <button
                        key={1}
                        className={`history__btn_pagin ${currentPage === 1 ? 'history__pagin_page-active' : ''}`}
                        onClick={() => handleClick(1)}
                    >
                        1
                    </button>
                );
                pageNumbers.push(<button key="ellipsis1" className="history__pagin_ellipsis">...</button>);
                for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
                    pageNumbers.push(
                        <button
                            key={i}
                            className={`history__btn_pagin ${currentPage === i ? 'history__pagin_page-active' : ''}`}
                            onClick={() => handleClick(i)}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                pageNumbers.push(
                    <button
                        key={1}
                        className={`history__btn_pagin ${currentPage === 1 ? 'history__pagin_page-active' : ''}`}
                        onClick={() => handleClick(1)}
                    >
                        1
                    </button>
                );
                pageNumbers.push(<button key="ellipsis1" className="history__pagin_ellipsis">...</button>);
                if (windowWidth <= 860) {
                    pageNumbers.push(
                        <button
                            key={currentPage}
                            className={`history__btn_pagin ${currentPage === currentPage ? 'history__pagin_page-active' : ''}`}
                            onClick={() => handleClick(currentPage)}
                        >
                            {currentPage}
                        </button>
                    );
                } else {
                    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                        pageNumbers.push(
                            <button
                                key={i}
                                className={`history__btn_pagin ${currentPage === i ? 'history__pagin_page-active' : ''}`}
                                onClick={() => handleClick(i)}
                            >
                                {i}
                            </button>
                        );
                    }
                }
                pageNumbers.push(<button key="ellipsis2" className="history__pagin_ellipsis">...</button>);
                pageNumbers.push(
                    <button
                        key={totalPages}
                        className={`history__btn_pagin ${currentPage === totalPages ? 'history__pagin_page-active' : ''}`}
                        onClick={() => handleClick(totalPages)}
                    >
                        {totalPages}
                    </button>
                );
            }
        }
        return pageNumbers;
    };

    return (
        <section className='history'>
            <div className='history__title_container'>
                <h2 className='history__title'><span>и</span>стория <span>игр</span></h2>
                <img 
                    className='history__shot'
                    src={require('../../images/profile/shot-history.svg').default}
                    alt='shot'
                >
                </img>
            </div>

            <ul className='history__table_header'>
                <li className='history__table_header-item'>№</li>
                <li className='history__table_header-item'>Дата</li>
                <li className='history__table_header-item'>Название заведения</li>
                <li className='history__table_header-item'>Адрес</li>
                <li className='history__table_header-item'>Заказ</li>
                <li className='history__table_header-item'>Начислено</li>
            </ul>
            <div className='history__table_list'>
                {currentItems.map((item, index) => (
                <div className='history__item' key={index}>
                    <h3 className='history__item_num'>{indexOfFirstItem + index + 1}</h3>
                    <p className='history__item_date'>{item.date}</p>
                    <p className='history__item_place'>{item.place}</p>
                    <p className='history__item_address'>{item.address}</p>
                    <p className='history__item_orders-title'>Заказ:</p>
                    <ul className='history__item_orders'>
                        {Object.values(item.order).map((order, orderIndex) => (
                            <li className='history__item_order-item' key={orderIndex}>{order}</li>
                        ))}
                    </ul>
                    <p className='history__item_coins'>+{item.coin}</p>
                </div>    
                ))}                           
            </div>
            <div className='history__pagin'>
                <button
                    className={`history__pagin_left history__btn_pagin ${currentPage !== 1 && 'history__pagin_arrow-active'}`}
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                ></button>
                {renderPageNumbers()}
                <button
                    className={`history__pagin_right history__btn_pagin ${currentPage !== totalPages && 'history__pagin_arrow-active'}`}
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                ></button>
            </div>
        </section>
    );
}

export default HistoryGames;
