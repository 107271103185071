import React from 'react';
import Previous from '../Previous/Previous';
import avatar from '../../images/profile/woman.svg';
import HistoryGames from '../HistoryGames/HistoryGames';
import PlayerCard from '../PlayerCard/PlayerCard';

function Profile( props ) {

    const { windowWidth } = props;

    return (
        <main className='profile'>
            <Previous />
            
            <h2 className='profile__title_mobile'>
                <img 
                    className='profile__shot'
                    src={require('../../images/profile/shot_profile.svg').default}
                    alt='shot'
                >
                </img>
                <span>о</span>б <span>игроке</span>
            </h2>

            <div className='profile__about_container'>
                <img
                    className='profile__about_avatar'    
                    src={avatar}
                    alt='avatar'
                />
                <div className="profile__info_container">
                    <div className='profile__title_container'>
                        <h2 className='profile__title'>
                            <img 
                                className='profile__shot'
                                src={require('../../images/profile/shot_profile.svg').default}
                                alt='shot'
                            >
                            </img>
                            <span>о</span>б <span>игроке</span>
                        </h2>
                        <h2 className='profile__title_rank'>365<span>место в общем рейтинге</span></h2>
                    </div>

                    <div className='profile__about_info'>
                        <ul className='profile__info_list'>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Ник</p>
                                <p className='profile__info_value profile__info_value-nik'>@romashka</p>
                                <div></div>
                            </li>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Имя</p>
                                <p className='profile__info_value'>Маша</p>
                                <a className='profile__info_edit'>Изменить</a>
                            </li>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Фамилия</p>
                                <p className='profile__info_value'>Сергеева</p>
                                <a className='profile__info_edit'>Изменить</a>
                            </li>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Телефон</p>
                                <p className='profile__info_value'>-</p>
                                <a className='profile__info_edit'>Изменить</a>
                            </li>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Почта</p>
                                <p className='profile__info_value'>mail@mail.ru</p>
                                <a className='profile__info_edit'>Изменить</a>
                            </li>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Пароль</p>
                                <p className='profile__info_value'>*****
                                    <img
                                        className='profile__pass_eye'
                                        src={require('../../images/profile/eye.svg').default}
                                        alt='eye'
                                    ></img>
                                </p>
                                <a className='profile__info_edit'>Изменить</a>
                            </li>
                            <li className='profile__info_row'>
                                <p className='profile__info_param'>Аватар</p>
                                <p className='profile__info_value'>Ж</p>
                                <a className='profile__info_edit'>Изменить</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <HistoryGames windowWidth={windowWidth}/>
            <PlayerCard/>
        </main>
    );
}

export default Profile;