import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTopOnRouteChange from '../ScrollToTopOnRouteChange/ScrollToTopOnRouteChange.js';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Main from '../Main/Main';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SIgnUp';
import Popup from '../Popup/Popup.js';
import Profile from '../Profile/Profile.js'
import Card from '../Card-page/Card.js'

function App() {
    const [popupOpen, setPopupOpen] = useState (false);
    const [loggedIn, setLoggedIn] = useState (false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        const debounceResize = () => {
            clearTimeout(window.debounceTimeout);
            window.debounceTimeout = setTimeout(handleResize, 500);
        };

        window.addEventListener('resize', debounceResize);

        return () => {
            window.removeEventListener('resize', debounceResize);
            clearTimeout(window.debounceTimeout);
        };
    }, []);

    return (
        <BrowserRouter>
            <div className="page">
                <Header windowWidth={windowWidth} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
                <ScrollToTopOnRouteChange />
                <Routes>
                    <Route path="/" element={<Main windowWidth={windowWidth} />} />
                    <Route path="/signin" element={<SignIn setLoggedIn={setLoggedIn}/>} />
                    <Route path="/signup" element={<SignUp setPopupOpen = {setPopupOpen}/>} />
                    <Route path="/profile" element={<Profile windowWidth={windowWidth}/>} />
                    <Route path="/card" element={<Card />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />
                <Popup 
                    popupOpen={popupOpen} 
                    setPopupOpen={setPopupOpen}
                />
            </div>
        </BrowserRouter>
    );
}

export default App;

