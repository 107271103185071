import React, { useState } from 'react';
import AuthForm from '../AuthForm/AuthForm';
import { useNavigate } from 'react-router-dom';

function SignIn( props ) {
    const { setLoggedIn } = props;
    // const [name, setName] = useState('');
    const [errorName, setErrorName] = useState('');
    const [isNameDirty, setIsNameDirty] = useState(true);
    // const [pass, setPass] = useState('');
    const [errorPass, setErrorPass] = useState('');
    const [isPassDirty, setIsPassDirty] = useState(true);
    const navigate = useNavigate();

    function handleName(e) {
        // setName(e.target.value);
        if (e.target.value.length < 2 || e.target.value.length > 30) {
          setIsNameDirty(true);
          setErrorName('Длина имени должна быть от 2 до 30 символов');
        } else {
          setIsNameDirty(false);
          setErrorName('');
        }
    }

    function handlePass(e) {
        // setPass(e.target.value);
        if (e.target.value.length < 4) {
          setIsPassDirty(true);
          setErrorPass('Длина пароля должна быть более 4-х символов');
        } else {
          setIsPassDirty(false);
          setErrorPass('');
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        setLoggedIn(true);
        navigate('/');
    }

    return (
        <AuthForm submitTitle="Войти" handleSubmit={ handleSubmit } dirtyInputs={isPassDirty || isNameDirty}>
            <div className='signin__title_container'>
                <img 
                    className='signin__shot'
                    src={require('../../images/shot_signin.svg').default}
                    alt='выстрел'
                ></img>
                <h2 className='authform__title'><span>В</span>ход <span>в ЛК</span></h2>
            </div>
            <label className='authform__label'>Логин (ник)</label>
            <input 
                className='authform__input' 
                type="text" 
                name="name" 
                onChange={ handleName }
                required
            />
            <span className="authform_error">{ errorName }</span> 
            <label className='authform__label'>Пароль</label>
            <input 
                className='authform__input' 
                type="password" 
                name="password" 
                required 
                onChange={ handlePass }
            />
            <span className="authform_error">{ errorPass }</span> 
        </AuthForm>
    );
}

export default SignIn;