import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';

function Previous(  ) {
    const navigate = useNavigate(); 

    function goBack() {
        navigate(-1, { replace: true });
      }

    return (
        <NavLink 
            className='previous'
            to='#' 
            onClick={goBack} 
        >
            &lt;&nbsp;&nbsp;&nbsp;Назад
        </NavLink>

    );
}

export default Previous;

