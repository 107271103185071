import React from 'react';
import { ratingList } from '../../../constants/data';

function Rating() {

    return (
        <section className="rating" id='rating'>
            <div className='rating__container'>
                <img 
                    className='rating__shot'
                    src={require('../../../images/shot-rating.svg').default}
                    alt='выстрел'
                ></img>
                <h2 className='rating__title'><span>Т</span>ОП <span>5</span> ИРГОКОВ</h2>
                <ul className='rating__table_header'>
                    <li className='rating__table_header-item'>№</li>
                    <li className='rating__table_header-item'>Аватар</li>
                    <li className='rating__table_header-item'>Никнейм</li>
                    <li className='rating__table_header-item'>Достижения</li>
                    <li className='rating__table_header-item'>Уровень</li>
                    <li className='rating__table_header-item'>Страна</li>
                    <li className='rating__table_header-item'>Город</li>
                </ul>
                <div className='rating__table_list'>
                    {ratingList.slice(0, 5).map((item, index) => (
                    <div className='rating__item' key={index}>
                        <h3 className='rating__item_num'>{index + 1}</h3>
                        <img 
                            className='rating__item_avatar'
                            src={require(`../../../images/avatars/${item.avatar}.png`)}
                            alt='аватар'
                        >
                        </img>
                        <p className='rating__item_nikname'>{item.nikname}</p>
                        <span className='rating__item_span'>Достижения</span><p className='rating__item_progress'>{item.progress}</p>
                        <span className='rating__item_span'>Уровень</span><p className='rating__item_level'>
                            {[...Array(parseInt(item.level))].map((_, i) => (
                                <img 
                                    key={i}
                                    className='rating__item_level-bullet'
                                    src={require('../../../images/bullet-rating.png')}
                                    alt='пуля'
                                />
                            ))}
                        </p>
                        <span className='rating__item_span'>Страна</span><p className='rating__item_country'>{item.country}</p>
                        <span className='rating__item_span'>Город</span><p className='rating__item_city'>{item.city}</p>
                    </div>    
                    ))}                           
                </div>
                <button className='btn rating__full-rating'>Смотреть полную таблицу результатов</button>
            </div>
 
        </section>
    );
}

export default Rating;