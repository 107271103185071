import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    function goToMainPage() {
        navigate('/');
    }

    return (
        <section className="footer">
            <div className='footer__container'>
                <img 
                    className='footer__logo'
                    src={require('../../images/logo.png')}
                    alt='логотип'
                    onClick={goToMainPage}
                ></img>    
                <p className='footer__copyright'>2024. Все права защищены.</p>
                <div className='footer__contacts'>
                    <p className='footer__phone'>+7 (123) 456-78-91</p>
                    <p className='footer__email'>mail@mail.ru</p>
                </div>
                <form className='footer__subscription_container'>
                    <p className='footer__subscription_title'>Подпишитесь на рассылку и будьте всегда в курсе наших новостей!</p>
                    <input className='footer__subscription_input' type='email' placeholder='e-mail'></input>
                    <button className='btn footer__subscription_btn' type='submit'>Подписаться</button>
                    <p className='footer__subscription_caption'>
                        Нажимая на кнопку, вы соглашаетесь <a href="/">с политикой конфиденциальности</a>
                    </p>
                </form>

                <div className='footer__social'>
                    <p className='footer__social_title'>
                        Подпишитесь на наши соцсети, следите 
                        за RR и отмечайте нас в своих постах!
                    </p>
                    <div className='footer__social_links'>
                        <a 
                            className='footer__social_link-item'
                            href='https://vk.com' 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img 
                                className='footer__social_link-icon'
                                src={require('../../images/vk.svg').default}
                                alt='Вконтакте'
                            ></img>
                            VKontakte
                        </a>
                        <a 
                            className='footer__social_link-item'
                            href='https://web.telegram.org/' 
                            target="_blank"
                            rel="noopener noreferrer"
                        
                        >
                            <img 
                                className='footer__social_link-icon'
                                src={require('../../images/tg.svg').default}
                                alt='Телеграм'
                            ></img>
                            Telegram
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;