import React from 'react';
import Intro from './Intro/Intro';
import About from './About/About'
import Video from './Video/Video';
import Rating from './Rating/Rating';
import Locations from './Locations/Locations';

function Main( props ) {
    const { windowWidth } = props;
    return (
        <div className="main">
            <Intro/>
            <About/>
            <Video/>
            <Rating/>
            <Locations windowWidth={windowWidth}/>
        </div>
    );
}

export default Main;