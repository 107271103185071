import React from 'react';
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import placemarkIcon from '../../images/placemark.png';
import exampleBar from '../../images/example_bar.png';

const YandexMap = () => {
    const balloonContent = `
        <div style="padding: 10px; width: 365px; height: 212px; position: relative;">
            <h3 
                style=
                    "
                        margin: 0 0 10px 0;
                        max-width: 235px;
                        text-decoration: underline;
                        color: #161619;
                    "
                >
                Аппетитный перерыв
            </h3>
            <img src="${exampleBar}" alt="Example" 
                style=
                    "
                        font-family: Inter;
                        font-size:18px;
                        font-weight: 500;
                        line-height: 130%
                        width: 100px; 
                        height: auto;
                        margin-bottom: 10px;
                        position: absolute;
                        right: 0;
                        top: 16px;
                    " 
                />
            <p 
                style=
                    "
                        margin: 0;
                        color: #959595;
                        max-width: 235px;
                    "
                >
                кафе-бар, ул. Профессора Попова, д. 31
            </p>
            <p 
                style=
                    "
                        font-family: Inter;
                        font-size:14px;
                        font-weight: 600;
                        line-height: 130%
                        color: #161619;
                        margin: 15px 0 0 0;
                        max-width: 335px;
                    "
                >
                Напитки RR:
            </p>
            <ul 
                style=
                    "
                    font-family: Inter;
                    font-size:14px;
                    font-weight: 400;
                    line-height: 130%
                    list-style-type: disc;
                    margin-left: 20px;
                    "
            >
                <li style="color: #35384A;">Капучино Единорог на волне успеха</li>
                <li style="color: #35384A;">Коктейль Капибара</li>
            <ul>
        </div>
    `;

    return (
        <YMaps>
            <div style={{ width: '100%', height: '100%' }}>
                <Map defaultState={{ center: [59.93863, 30.31413], zoom: 10 }} width='100%' height='100%'>
                    <Placemark 
                        geometry={[59.934999, 30.331033]} 
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: placemarkIcon, 
                            iconImageSize: [50, 73], 
                            iconImageOffset: [-25, -73], 
                        }}
                        properties={{
                            balloonContent: balloonContent,
                        }}
                        modules={['geoObject.addon.balloon']}
                    />
                    <ZoomControl />
                </Map>
            </div>
        </YMaps>
    );
};

export default YandexMap;
