import React from 'react';
import { useNavigate } from 'react-router-dom';

function Intro() {
    const navigate = useNavigate();

    function goToSignUpPage() {
        navigate('/signup');
    }

    return (
        <section className="intro">
            <img 
                className='intro__gun'
                src={require('../../../images/gun.png')}
                alt='пистолет'
            >
            </img>

            <div className='intro__info_container'>
                <img 
                    className='intro__shot'
                    src={require('../../../images/shot.png')}
                    alt='выстрел'
                >
                </img>

                <h1 className='intro__title'>russian<br/><span className='intro__title_span'></span>roulette</h1>
                <p className='intro__subtitle'>
                    Здесь должен быть краткий текст/слоган игры: чтобы 
                    заходя на главный экран челоек сразу понимал что это 
                    (можно примерно на 3-4 строки)
                </p>

                <button className='intro__button_container' onClick={goToSignUpPage}>
                    <p className='intro__button_text'>Присоединиться к игре</p>
                    <img 
                        className='intro__button_bullet'
                        src={require('../../../images/bullet.svg').default}
                        alt='пуля'
                    ></img>
                </button>
            </div>
        </section>
    );
}

export default Intro;