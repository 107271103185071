import React, { useState } from 'react';
import Dropdown from './Dropdown/Dropdown';
import { CountryList, CityList} from '../../../constants/data';
import YandexMap from '../../YandexMap/YandexMap';

function Locations( props ) {
    const { windowWidth } = props;
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCountryValue, setSelectedCountryValue] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const [checked, setChecked] = useState(true);

    const handleToggleChange = () => {
       setChecked((check) => !check);
    };

    const handleCountryChange = (option) => {
        setSelectedCountry(option.value);
        setSelectedCountryValue(option);
        setSelectedCity(null);
    };

    const handleCityChange = (option) => {
        setSelectedCity(option);
    };

    const filteredCityList = CityList.filter(city => city.country === selectedCountry);
    const exampleList = (
        <div>
            <h3 className='locations__slider_letter'>А</h3>
            <p className='locations__slider_name'>Авокадо</p>
            <p className='locations__slider_name locations__slider_name-active'>Аппетитный перерыв</p>
            <p className='locations__slider_name'>Атмосфера вкуса</p>
            <p className='locations__slider_name'>Американский пирог</p>
            <p className='locations__slider_name'>Апельсиновый рай</p>
        </div>
    );

    function widthMap() {
        if (windowWidth > 860) {
            return { width: '100%' };
        } else {
            if (checked) return { width: '100%' };
            else return { width: '0' };
        }
    }

    return (
        <section className="locations" id='locations'>
            <div className='locations__container'>
                <img 
                    className='locations__shot'
                    src={require('../../../images/shot-locations.svg').default}
                    alt='выстрел'
                />
                <h2 className='locations__title'><span>Карта</span> заведени<span>й</span></h2>
                <div className="locations__dropdown-container">
                    <Dropdown 
                        options={CountryList} 
                        placeholder="Страна" 
                        onChange={handleCountryChange} 
                        selectedOption={selectedCountryValue}
                    />
                    <Dropdown 
                        options={filteredCityList} 
                        placeholder="Город" 
                        onChange={handleCityChange}
                        selectedOption={selectedCity}
                    />
                </div>
                <label className="locations__toggle-mobile">
                    <p>Список</p>
                    <p>Карта</p>
                     <input
                        className='locations__toggle-input'
                        type="checkbox"
                        id='toggle'
                        name='toggle'
                        checked={checked}
                        onChange={handleToggleChange}
                     />
                    <span className='locations__toggle-slider'>
                        <span className='locations__toggle_span-left' style={checked ? { color: '#54555A' } : {}}>Список</span>
                        <span className='locations__toggle_span-right'style={!checked ? { color: '#54555A' } : {}}>Карта</span>
                    </span>
                </label>
                <div className='locations_map_container'>
                    <div className='locations__slider_container'>
                        <div className='location__slider_finder-container'>
                            <input className='locations__slider_finder'></input>
                        </div>
                        <div className='locations__slider'>
                            {exampleList}
                            {exampleList}
                            {exampleList}
                            {exampleList}
                            {exampleList}
                            {exampleList}
                        </div>
                    </div>
                    <div className='locations__map' 
                        style={widthMap()}
                    >
                        <YandexMap/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Locations;
