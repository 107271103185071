import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthForm from '../AuthForm/AuthForm';

function SignUp( props ) {
    const navigate = useNavigate();
    const { setPopupOpen } = props;
    // const [name, setName] = useState('');
    const [errorName, setErrorName] = useState('');
    const [isNameDirty, setIsNameDirty] = useState(true);
    const nameInputRef = useRef(null);

    useEffect(() => {
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, []);
    

    function handleSubmit(e) {
        e.preventDefault();
        navigate('/signin');
        setPopupOpen(true);
    }

    function handleName(e) {
        // setName(e.target.value);
        if (e.target.value.length < 2 || e.target.value.length > 30) {
          setIsNameDirty(true);
          setErrorName('Длина имени должна быть от 2 до 30 символов');
        } else {
          setIsNameDirty(false);
          setErrorName('');
        }
    }

    return (
        <AuthForm 
            submitTitle="Зарегистрироваться" 
            handleSubmit={handleSubmit} 
            dirtyInputs={isNameDirty}
            
        >
            <div className='signup__title_container'>
                <img 
                    className='signup__shot'
                    src={require('../../images/shot_signup.svg').default}
                    alt='выстрел'
                ></img>
                <h2 className='authform__title'><span>Р</span>егистраци<span>я</span></h2>
            </div>
            <label className='authform__label'>Придумайте ник:</label>
            <input 
                ref={nameInputRef}
                className='authform__input' 
                type="text" 
                name="name" 
                onChange={ handleName }
                required
            />
            <span className="authform_error">{ errorName }</span> 
        </AuthForm>
    );
}

export default SignUp;