import React from 'react';
import Previous from '../Previous/Previous';
import PlayerCard from '../PlayerCard/PlayerCard';

function Card( props ) {
    return (
        <main className='card'>
            <Previous className='card__previous'/>
            <PlayerCard/>
        </main>
    );
}

export default Card;

