import React from 'react';

function About() {
  return (
    <section className='about' id='about'>
        <div className='title__container'>
            <img 
                className='about__shot'
                src={require('../../../images/shot-about.svg').default}
                alt='выстрел'
            >
            </img>
            <h2 className='about__title'><span>Об</span> игр<span>е</span></h2>
        </div>
        <div className='about__container'>
            <div className='about__content'>
                <h3 className='about__content_title'>Правила игры</h3>
                <p className='about__content_text'>
                    Здесь должен быть текст с объяснениями правил 
                    игры. Lorem ipsum dolor sit amet consectetur. Imperdiet 
                    aliquet massa vitae vitae habitant quisque. Nibh 
                    quisbitant quisque. Nibh quisbitant quisque. Nibh 
                    quisbitant quisque. Nibh quisque consectetur quam 
                    laoreet in aliquet sed. Viverra et et neque morbi ante 
                    porttitor mauris in. Consectetur quis eros dui imperdiet.
                </p>
                <br/>
                <p className='about__content_text'>
                    Lorem ipsum dolor sit amet consectetur. Imperdiet aliquet 
                    massa vitae vitae habitant quisque. Nibh quisbitant 
                    quisque. Nibh quisbitant quisque. Nibh quisbitant 
                    quisque. Nibh quisque consectetur quam laoreet in aliquet 
                    ed. Viverra et et neque morbi ante porttitor mauris in. 
                    Consectetur quis eros dui imperdiet.
                </p>
            </div>
            <div className='about__content'>
                <h3 className='about__content_title'>Карта игрока</h3>
                <p className='about__content_text'>
                    Здесь должен быть текст с объяснениями о том как достигнуть 
                    какого-то уровня, как получить карту игрока и тп. Lorem ipsum 
                    dolor sit amet consectetur. Imperdiet aliquet massa vitae vitae 
                    habitant quisque. Nibh quisbitant quisque. Nibh quisbitant 
                    quisque. Nibh quisbitant quisque. Nibh quisque consectetur quam 
                    laoreet in aliquet sed. Viverra et et neque morbi ante porttitor 
                    mauris in. Consectetur quis eros dui imperdiet.
                </p>
                <br/>
                <p className='about__content_text'>
                    Lorem ipsum dolor sit amet consectetur. Imperdiet aliquet massa 
                    vitae vitae habitant quisque. Nibh quisbitant quisque. Nibh 
                    quisbitant quisque. Nibh quisbitant quisque. 
                </p>
            </div>
        </div>
    </section>
  );
}

export default About;