import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Previous from '../Previous/Previous'

function AuthForm( props ) {
    const { submitTitle, handleSubmit, children, dirtyInputs } = props;
    const location = useLocation();

    return (
        <main className='authform'>
            <Previous/>
            <div className='authform__container'>
                <form className='authform__form' onSubmit={handleSubmit}>
                    {children}
                    <button 
                        className={`btn authform__submit ${dirtyInputs && 'authform__submit_disabled'}`}
                        type="submit"
                        disabled={dirtyInputs}
                        // style={location.pathname === '/signup' ? { marginTop: '40px' } : {}}
                    >
                        {submitTitle}
                    </button>
                    {((location.pathname === '/signin') 
                        ? 
                            (
                                <p className='authform__caption'>Еще нет аккаунта? <br/><NavLink className='authform__caption_link' to='/signup'>Зарегистрироваться</NavLink>
                                </p>
                            )
                        :
                            ('')
                    )}
                </form>
            </div>
        </main>
    );
}

export default AuthForm;

