import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';

function Header(props) {
    const { windowWidth, loggedIn, setLoggedIn } = props;
    const [isSlideMenuOpen, setIsSlideMenuOpen] = useState(false);
    const [isSlideMenuClose, setIsSlideMenuClose] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    function goToMainPage() {
        navigate('/');
        closeMenu(true);
    }

    function goToSignInPage() {
        navigate('/signin');
        closeMenu(true);
    }

    function goToSignUpPage() {
        navigate('/signup');
        closeMenu(true);
    }

    function handleSignOut() {
        setLoggedIn(false);
        navigate('/signin');
        closeMenu(true);
    }

    function goToProfile() {
        navigate('/profile');
        closeMenu(true);
    }

    function handleCheckboxChange(e) {
        setIsSlideMenuOpen(!isSlideMenuOpen);
        if (!isSlideMenuOpen) {
            setIsSlideMenuClose(true);
        }
    }

    function closeMenu(close) {
        setIsSlideMenuClose(close);
        setIsSlideMenuOpen(!close);
    }

    useEffect(() => {
        if (windowWidth > 1024) {
            setIsSlideMenuOpen(false);
            setIsSlideMenuClose(false);
        }
    }, [windowWidth]);

    useEffect(() => {
        if (location.hash) {
            const hash = location.hash.replace('#', '');
            scroller.scrollTo(hash, {
                duration: 500,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -110,
            });
        }
    }, [location]);

    function handleNavigation(to) {
        if (location.pathname === '/') {
            scroller.scrollTo(to, {
                duration: 500,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -110, 
            });
        } else {
            navigate(`/#${to}`);
        }
        closeMenu(true);
    }

    return (
        <>
            <div className="header">
                {
                    <div 
                        className={`header__dark-background ${isSlideMenuOpen ? 'header__dark-background-active' : ''}`}
                        onClick={() => {closeMenu(true)}}
                    >
                    </div>
                }
                <div className='header__container'>
                    <img 
                        className='header__logo'
                        src={require('../../images/logo.png')}
                        alt='логотип'
                        onClick={goToMainPage}
                    ></img>

                    
                    <div className='header__burger_container' onClick={handleCheckboxChange}>
                        <div 
                            className={`burger ${isSlideMenuOpen ? 'burger_active' : ''}`} 
                        >
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div className={`header__menu_container ${isSlideMenuOpen ? 'header__menu-mobile_active' : ''} ${isSlideMenuClose ? 'header__menu-mobile_inactive' : ''}`}>
                    <div className='header__menu'>
                        <ScrollLink 
                            className='text-menu header__menu_item-mobile'
                            to="main" 
                            smooth={true} 
                            offset={-103}
                            duration={500}
                            onClick={() => handleNavigation('main')}
                        >
                            Главная
                        </ScrollLink>
                        <ScrollLink 
                            className='text-menu' 
                            to="about" 
                            smooth={true} 
                            offset={-103}
                            duration={500}
                            onClick={() => handleNavigation('about')}
                        >
                            Об игре
                        </ScrollLink>
                        <ScrollLink 
                            className='text-menu' 
                            to="rating" 
                            smooth={true} 
                            offset={-103}
                            duration={500}
                            onClick={() => handleNavigation('rating')}
                        >
                            Рейтинг
                        </ScrollLink>
                        <ScrollLink 
                            className='text-menu'
                            to="locations"  
                            smooth={true} 
                            offset={50}
                            duration={500}
                            onClick={() => handleNavigation('locations')}
                        >
                            Карта заведений
                        </ScrollLink>
                        {
                            loggedIn 
                            ? 
                            (
                            <NavLink 
                                className='text-menu header__profile-text'
                                to="/profile"  
                                onClick={() => {closeMenu(true)}}
                            >
                                Личный кабинет
                            </NavLink>
                            )
                            :
                            (<></>) 
                        }
                        {
                            loggedIn 
                            ? 
                            (
                            <NavLink 
                                className={`text-menu header__player-card ${(location.pathname === '/card') ? ('header__player-card_active') : ('')}`}
                                to="/card" 
                                onClick={() => {closeMenu(true)}}
                            >
                                Карта игрока
                            </NavLink>
                            )
                            :
                            (<></>)
                            }
                    </div>
                        {loggedIn ? 
                            (
                            <div className='header__menu_btns-mobile'>
                                <button 
                                    className='btn text-menu header__signout-mobile'
                                    onClick={handleSignOut}
                                >
                                    Выйти
                                </button>
                            </div>
                            )
                            :
                            (
                            <div className='header__menu_btns-mobile'>
                                <button 
                                    className='btn text-menu header__signin-mobile' 
                                    onClick={goToSignInPage}
                                >
                                    вход
                                </button>
                                <button 
                                    className='btn text-menu header__signup-mobile'
                                    onClick={goToSignUpPage}
                                >
                                    регистрация
                                </button>
                            </div>
                            )}
                    </div>
                    <div className='header__btns'>
                        <button className='header__lang'>
                            <p className='header__lang_text'>RUS</p>
                            <img 
                                className='header__lang_flag'
                                src={require('../../images/flag.png')}
                                alt='флаг'
                            />
                        </button>
                        {loggedIn 
                        ? 
                        (
                            <div className='header__buttons_container'>
                                <button 
                                    className='btn text-menu header__signout'
                                    onClick={handleSignOut}
                                >
                                    Выйти
                                </button>
                                <button 
                                    className='btn text-menu header__profile'
                                    onClick={goToProfile}
                                >
                                </button>
                             </div>
                        ) 
                        : 
                        (
                            <div className='header__buttons_container'>
                                <button 
                                    className='btn text-menu header__signup'
                                    onClick={goToSignUpPage}
                                >
                                    регистрация
                                </button>
                                <button 
                                    className='btn text-menu header__signin'
                                    onClick={goToSignInPage}
                                >
                                    вход
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='header__ident'></div>
        </>
    );
}

export default Header;
