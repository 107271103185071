import React, { useEffect } from 'react';

function Popup( props ) {
    const { popupOpen, setPopupOpen } = props;

    function closePopup() {
        setPopupOpen(false);  
    }

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setPopupOpen(false); 
      }
    };

    if (popupOpen) {
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [popupOpen, setPopupOpen]);

    return (
        <div className={`popup ${popupOpen && 'popup__opened'}`}>
            <div className=' popup__container'>
                <button className='popup__close'onClick={closePopup}></button>
                <div className='popup__content'>
                    <p className='popup__conetnt_text'>Спасибо за регистрацию!</p>
                    <p className='popup__conetnt_text'>
                        Пожалуйста, <span>запомните или сделайте скрин</span> данных ниже. 
                        В случае их утери, <span>восстановление будет невозможно</span>
                    </p>
                    <p className='popup__conetnt_text'>Ваш логин (ник):&nbsp;&nbsp;&nbsp;<span>romashka</span> </p> 
                    <p className='popup__conetnt_text'>Ваш пароль:&nbsp;&nbsp;&nbsp;<span>1234</span> </p> 
                </div>
            </div>
        </div>
    );
}

export default Popup;